import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import SubscribeButton from '../components/SubscribeButton';
import { useUserContext } from '../contexts/UserContext';
import { PATHS } from '../utils/constants';
import useIsMobileL from '../hooks/useIsMobileL';

const SubscriptionPage = () => {
  const { user } = useUserContext();
  const isMobileL = useIsMobileL();
  const navigate = useNavigate();

  //note users directed here because they're plan expired will be redirected back to app maybe?
  // may not need that expires check in ProtectedRoutesWrapper anyway, if stripe webhook is setting planActive to false
  useEffect(() => {
    if (user?.planActive) {
      user?.hasUserSeenTutorial
        ? navigate(PATHS.CHAT)
        : navigate(PATHS.TEACHERS);
    }
  }, [navigate, user?.hasUserSeenTutorial, user?.planActive]);

  return (
    <div className='bg-blue-500 text-white h-screen flex flex-col gap-y-4 items-center justify-center p-12'>
      <h1
        className={classNames(
          'font-extrabold text-2xl text-center',
          isMobileL && 'whitespace-nowrap',
        )}
      >
        Your free trial has expired. Please subscribe to continue using Lingua
        Chat
      </h1>
      <SubscribeButton />
    </div>
  );
};

export default SubscriptionPage;
