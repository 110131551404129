import { useEffect, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';

const useIsTrialExpiringInNext3Days = () => {
  const [isTrialExpiringInNext3Days, setIsTrialExpiringInNext3Days] =
    useState(false);
  const { user } = useUserContext();

  useEffect(() => {
    //TODO due to stripe webhook, they might update planExpires every month...so may need new user db property `trialExpires` to check if trial is expiring in next 3 days
    if (!user.planExpires) {
      return;
    }

    const currentTimestamp = Math.floor(Date.now() / 1000);
    const threeDaysInSeconds = 3 * 24 * 60 * 60;
    const isExpiring =
      user.planExpires <= currentTimestamp + threeDaysInSeconds;

    setIsTrialExpiringInNext3Days(isExpiring);
  }, [user.planExpires]);

  return isTrialExpiringInNext3Days;
};

export default useIsTrialExpiringInNext3Days;
